import {
  ClipboardDocumentListIcon,
  Cog8ToothIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/vue/24/outline';

export const NavigationConfig = Object.freeze({
  main: [
    {
      name: 'Prescriptions',
      href: '/prescriptions',
      icon: ClipboardDocumentListIcon,
    },
    { name: 'Settings', href: '/settings', icon: Cog8ToothIcon },
    { name: 'Support', href: '/support', icon: QuestionMarkCircleIcon },
  ],
  tools: [
    { name: 'Invoices', href: '/invoices', initial: 'IV' },
    { name: 'Reporting', href: '/reporting', initial: 'RE' },
  ],
  prescriptions: {
    tabs: [
      { name: 'All', value: 'all' },
      { name: 'Issued', value: 'issued' },
      { name: 'Dispensed', value: 'dispensed' },
      { name: 'Invalidated', value: 'invalidated' },
      { name: 'Drafts', value: 'draft' },
    ],
    queryKey: 'status',
  },
  settings: {
    tabs: [
      { name: 'Account', value: 'account' },
      { name: 'Security', value: 'security' },
      { name: 'Integrations', value: 'integrations' },
    ],
    queryKey: 'section',
  },
  invoices: {
    tabs: [
      { name: 'All', value: 'all' },
      { name: 'Unpaid', value: 'unpaid' },
      { name: 'Paid', value: 'paid' },
    ],
    queryKey: 'status',
  },
});
